import {Card, Button, Drawer, Spin,Tooltip, Tag} from 'antd';
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Routes} from '../../utils/route';
import AppLayout from '../../components/layout';
import List from './comp/List';
import {TextCapitalize,TextUpperCase,IndustryType } from '../../utils/helper'; 
import {
  getAttentionList,
  PerformAttentionAction,
} from '../../actions/attentionAction';
import './attention.css';
import {usePosition} from '../../utils/usePosition';
import PageHeader from '../../components/pageHeader/PageHeader';


const mapSatateToProps = state => {
  return {
    loading: state.attention.loading,
    list: state.attention.list,
    count:state.attention.count,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAttentionList: () => dispatch (getAttentionList ()),
    PerformAttentionAction: data => dispatch (PerformAttentionAction (data)),
  };
};

const text = (data) => <text>{data}</text>

const tableColumns = [
  {
    label: 'Equipment No.',
    name: 'Equipment',
    type:'chemical',
    options: {filter: false,sort: true,customBodyRender: value =>TextUpperCase(value)}
  },
  {
    label: 'Equipment Type',
    name: 'equipment_type',
    type:'chemical',
    options: {filter: false,sort: true,customBodyRender: value =>TextCapitalize(value)}
  },
  {
  name: "Equipment",
  label: "Trailer Unit No.",
  type:'food',
  options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}
  },
  {
  name: "truck_no",
  label: "Truck No.",
  type:'food',
  options: {filter: true,sort: true,customBodyRender: value =>TextUpperCase(value)}
},
  {
    label: 'owner',
    name: 'equipment_owner',
    options: {filter: false,sort: true,customBodyRender: value =>value!=="NA"?TextCapitalize(value):"NA"}
  },
  {
    label: 'Ticket No.',
    name: 'ticket_ref_id',
    options: {filter: false,sort: true}
  },
  {
    label: 'Status',
    name: 'Status',
    options: {filter: false,sort: true,customBodyRender:(root) =>(
      <Tag className="text-capitalize" color={"gold"}>
        {root}
      </Tag>)},
    
  },
  {
    label: 'Notes',
    name: 'remark',
    options: {filter: false,sort: true,customBodyRender: (root) => (<div>{root && root?.length > 30 ? <Tooltip placement="topLeft" label={text(root)}>{`${root.substring(0,29)}`}<div style={{color:'#3d9afe'}}>view more</div></Tooltip>:root}</div>),},
    
  },
  {
    label: 'Check In',
    name: 'check_in',
    options: {filter: false,sort: true,}
  },
  {
    label: 'Last Contained',
    name: 'last_tkt',
    type:'chemical',
    options: {filter: false,sort: true,customBodyRender: value =>TextCapitalize(value)}
  },
  {
    label: 'Jobsite',
    name: 'Jobsite',
    type:'chemical',
    options: {filter: false,sort: true,customBodyRender: value =>TextCapitalize(value)}
  },
  {
    label: 'User',
    name: 'user',
    options: {filter: false,sort: true,customBodyRender: value =>TextCapitalize(value)}
  },
  {
    label: 'Action',
    name: 'action',
    options: {filter: false,sort: true,}
  }
];

const tableColumnsFunc=()=>{
 let filter=[]
  if(IndustryType()==="Food_Grade"){
     filter=tableColumns.filter((item)=>{
      if(item.type==="food"||!item.type){
        return item
      }
    })
  }else{
     filter=tableColumns.filter((item)=>{
      if(item.type==="chemical"||!item.type){
        return item
      }
    })
  }
return filter
}


const columns = [
  {
    title: 'EQUIPMENT #',
    dataIndex: 'Equipment',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.Equipment;
        b = b.Equipment;
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'EQUIPMENT TYPE',
    dataIndex: 'equipment_type',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.equipment_type.toLowerCase ();
        b = b.equipment_type.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'EQUIPMENT OWNER',
    dataIndex: 'equipment_owner',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.equipment_owner.toLowerCase ();
        b = b.equipment_owner.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'TICKET NO.',
    dataIndex: 'ticket_ref_id',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.equipment_type.toLowerCase ();
        b = b.equipment_type.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'Status',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase ();
        b = b.Status.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    render:(_,root) =>(
    <Tag className="text-capitalize" color={"gold"}>
      {root.Status}
    </Tag>)
  },
  {
    title: 'NOTES',
    dataIndex: 'remark',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.remark.toLowerCase ();
        b = b.remark.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
    render: (_, root) => (<div>{root.remark && root.remark?.length > 30 ? <Tooltip placement="topLeft" title={text(root.remark)}>{`${root.remark.substring(0,29)}`}<div style={{color:'#3d9afe'}}>view more</div></Tooltip>:root.remark}</div>),
  },
  {
    title: 'CHECK IN',
    dataIndex: 'check_in',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.check_in.toLowerCase ();
        b = b.check_in.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'LAST CONTAINED',
    dataIndex: 'last_tkt',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.last_tkt.toLowerCase ();
        b = b.last_tkt.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'FROM/JOBSITE',
    dataIndex: 'Jobsite',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.Jobsite.toLowerCase ();
        b = b.Jobsite.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'USER',
    dataIndex: 'user',
    className: 'column',
    sorter: {
      compare: (a, b) => {
        a = a.user.toLowerCase ();
        b = b.user.toLowerCase ();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: 'ACTION',
    dataIndex: 'action',
    className: 'column',
    width: 80,
    fixed: 'right',
    sorter: true,
  },
];

const Attention = props => {
  const [selectedRowKeys, setSelectedRowKeys] = useState ([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState (false);
  const [drawerData, setDrawerData] = useState ({});
  const [loading, setLoading] = useState (true);
  const {latitude, longitude} = usePosition ();
  useEffect (() => {
    const callback = val => {
      if (val === true) {
        setLoading (false);
        setSelectedRowKeys ([]);
        props.getAttentionList ();
      }
    };
    Routes ('manager', callback);
  }, []);

  const onSelectChange = data => {
    setSelectedRowKeys (data);
  };

  const handleActions = (type, ticketID, remark) => {
    const data = {
      id: ticketID,
      status: type == 'approve' ? 3205 : 3250,
      remark: remark,
      coordinates: [latitude??	0.00, longitude??	0.00],
    };
    props.PerformAttentionAction (data);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: data => onSelectChange (data),
  };
  if (!loading) {
    return (
      <AppLayout>
        <PageHeader title="Attention Needed"/>
        <div>
          <List
            showDrawer={data => {
              setIsDrawerVisible (true);
            }}
            list={props.list ? props.list : []}
            loading={props.loading}
            rowSelection={rowSelection}
            columns={columns}
            tableColumns={tableColumnsFunc()}
            handleActions={(type, ticketID, remark) =>
              handleActions (type, ticketID, remark)}
              count={props.count}
          />
          <Drawer
            className="drawer"
            title="Basic Drawer"
            placement="right"
            // closable={false}
            onClose={() => setIsDrawerVisible (false)}
            visible={isDrawerVisible}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Drawer>
        </div>
      </AppLayout>
    );
  } else {
    return <div className="spinner-main"><Spin /></div>;
  }
};

export default connect (mapSatateToProps, mapDispatchToProps) (Attention);
